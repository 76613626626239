import { utils } from './module/_util';
import { uaObj } from './module/_ua_check';
import SmoothScroll from './module/_smoothscroll';


(() => {
  document.addEventListener('DOMContentLoaded', () => {
    const _globalNavi = $('#globalNavi');
    const _wrapper = $('#wrapper');
    let scrollTop = $(this).scrollTop();

    $('.target').scrollClass();
    SmoothScroll();
    utils.typeCheck();

    $('#btnMenu a').on('click', function () {
      $(this).toggleClass('active');
      _globalNavi.toggleClass('active');
    });


    const colorThemeChange = () => {
      if (scrollTop > 300 && !_wrapper.hasClass('fixed')) {
        if (!_wrapper.hasClass('light')) {
          _wrapper.removeClass('dark').addClass('light');
        }
      }
      else {
        if (!_wrapper.hasClass('dark')) {
          _wrapper.addClass('dark').removeClass('light');
        }
      }
    };

    $(window).on('scroll', function () {
      scrollTop = $(this).scrollTop();
      colorThemeChange();
    });

  }, false);
})();



