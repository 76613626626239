//
// usage
// https://keprate.com/web/javascript/ua-check/
//
// //iphoneの場合
// if (uaObj.agentName == 'iphone') $('body').addClass('ua-os-iphone');
// //macの場合
// if (uaObj.macFlag) $('body').addClass('ua-os-mac');
// //タブレットの場合
// if (uaObj.deviceName == 'TAB') $('body').addClass('ua-tab');
// //ie11の場合
// if (uaObj.browserName == 'ie11') $('body').addClass('ua-ie-11');
// //windowsのchromeの場合
// if (!uaObj.macFlag && uaObj.browserName == 'chrome') $('body').addClass('ua-win-chrome');


const ua = window.navigator.userAgent.toLowerCase();

let uaObj = {};

uaObj.agentName = '';
uaObj.deviceName = '';
uaObj.iosVer;

// スマホ
if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0) {
  uaObj.deviceName = 'SP';

  if (ua.search(/iphone/) != -1) {
    uaObj.agentName = 'iphone';

    //ios系　ver対応必要な場合記述
    if (ua.search(/11/) != -1) uaObj.iosVer = 11;
    if (ua.search(/10/) != -1) uaObj.iosVer = 10;
    if (ua.search(/9/) != -1) uaObj.iosVer = 9;
  }
  else if (ua.search(/android/) != -1) {
    uaObj.agentName = 'android';

    //android系　ver対応必要な場合記述
    if (ua.search(/android 4/) != -1) uaObj.agentName = 'android4';
  }
}

// タブレット
else if (ua.indexOf('ipad') > 0 || ua.indexOf('android') > 0) {
  uaObj.deviceName = 'TAB';

  if (ua.search(/ipad/) != -1) {
    uaObj.agentName = 'ipad';

    if (ua.search(/11/) != -1) uaObj.iosVer = 11;
    if (ua.search(/10/) != -1) uaObj.iosVer = 10;
    if (ua.search(/9/) != -1) uaObj.iosVer = 9;
  }
  else {
    uaObj.agentName = 'android';
  }
}

// PC
else {
  const ver = window.navigator.appVersion.toLowerCase();
  uaObj.browserName = '';
  uaObj.ieFlag = false;
  uaObj.macFlag = false;

  uaObj.deviceName = 'PC';

  if (ua.indexOf('msie') != -1) {
    if (ver.indexOf('msie 9.') != -1) {
      uaObj.browserName = 'ie9';
    }
    else if (ver.indexOf('msie 10.') != -1) {
      uaObj.browserName = 'ie10';
    }
    uaObj.ieFlag = true;
  }

  else if (ua.indexOf('trident/7') != -1) {
    uaObj.browserName = 'ie11';
    uaObj.ieFlag = true;
  }

  else if (ua.indexOf('edge') != -1) {
    uaObj.browserName = 'edge';
    uaObj.ieFlag = true;
  }

  else if (ua.indexOf('chrome') != -1) {
    uaObj.browserName = 'chrome';
  }

  else if (ua.indexOf('safari') != -1) {
    uaObj.browserName = 'safari';
  }

  else if (ua.indexOf('opera') != -1) {
    uaObj.browserName = 'opera';
  }

  else if (ua.indexOf('firefox') != -1) {
    uaObj.browserName = 'firefox';
  }

  var isMac = ((ua.indexOf('mac') > -1) && (ua.indexOf('os') > -1)) && !((ua.indexOf('iphone') > -1) || (ua.indexOf('ipad') > -1) || (ua.indexOf('windows') > -1));

  if (isMac) uaObj.macFlag = true;
}


export { uaObj };